import { SelectionChangedEvent } from "ag-grid-community";
import { PrintQueueEntity } from "domain/entity/Common/PrintQueueEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_PRINT_QUEUE_COL_DEF } from "presentation/constant/PrintQueue/PrintQueueMaintenanceColumnDefinition";
import { PrintQueueMaintenanceConstant } from "presentation/constant/PrintQueue/PrintQueueMaintenanceConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { usePrintQueueMaintenanceVM } from "presentation/hook/PrintQueue/usePrintQueueMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const PrintQueueTablePanel: React.FC = () => {
    const [printQueueState] = usePrintQueueMaintenanceTracked();
    const printQueueVM = usePrintQueueMaintenanceVM();
    const gridRef: any = useRef(null);
    const [anaInfoState] = useANAInfoTracked();
    const { allowDelete } = anaInfoState;
    const messageBarVM = useMessageBarVM();
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedRow, setDeletedRow] = useState<PrintQueueEntity>();
    const [isLoading, setIsLoading] = useState(false);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        printQueueVM.updateSelectedRows(selectedRows);
    }, [printQueueVM])

    const handleAddClick = useCallback(() => {
        printQueueVM.onAdd();
    }, [printQueueVM])

    const handleRowDoubleClick = useCallback((entity: PrintQueueEntity) => {
        printQueueVM.onOpenEdit(entity);
    }, [printQueueVM])

    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedRow) return;

        printQueueVM.onDelete(deletedRow).then((data) => {
            if (data) {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL)
                printQueueVM.onSearch().then(data => {
                    setIsLoading(false);
                });
            } else {
                messageBarVM.showError('Delete data failed.');
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleCancel = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        printQueueVM.onSearch().then(data => {
            setIsLoading(false);
        });
    }

    const handleDeleteClick = useCallback(async (data: PrintQueueEntity) => {
        setIsShowDelete(true);
        setDeletedRow(data);
    }, []);

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='print-queue-table'
                headerLabel={PrintQueueMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_PRINT_QUEUE_COL_DEF}
                data={printQueueState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={allowDelete}
                showActionColumn={true}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onDelete={(data: PrintQueueEntity) => handleDeleteClick(data)}
                onRowDoubleClick={(e: any, entity: PrintQueueEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 90px)"
                ref={gridRef}
            />
        );
    }, [printQueueState.tableData, handleAddClick, allowDelete, handleSelectionChange, handleDeleteClick, handleRowDoubleClick])

    return <>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <TableWrapper>
            {memoTable}
        </TableWrapper>
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${PrintQueueMaintenanceConstant.TITLE}`}
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default memo(PrintQueueTablePanel);
